import React from 'react'

const Hero = () => {
    return (
        <section id="home" class="hero-section">
            <div class="container">
                <div class="row align-items-center position-relative">
                    <div class="col-lg-6">
                        <div class="hero-content">
                            <h1 class="wow fadeInUp" data-wow-delay=".4s">
                                Transforming Your Vision into Reality
                            </h1>
                            <p class="wow fadeInUp" data-wow-delay=".6s">
                                We're Committed to Innovation and Achieving Remarkable Milestones, Fueled by Your Dreams.
                            </p>
                            <a href="#contact" class="main-btn border-btn btn-hover wow fadeInUp" data-wow-delay=".6s">Lets
                                work together</a>
                            <a href="#whatWeDo" class="scroll-bottom">
                                <i class="lni lni-arrow-down"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="hero-img wow fadeInUp " data-wow-delay=".5s">
                            <img class="hero_img" src="assets/img/hero/hero-img.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default Hero