import React from 'react'

const WhatWeDo = () => {
    return (
        <section id="whatWeDo" class="feature-section pt-120">
            <div class="container">
                <div class="section-title text-center mb-30">
                    <span class="title-tag">SERVICES</span>
                    <h1 class="title">What We Do</h1>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp">
                        <div class="single-feature">
                            <div class="icon">
                                <i class="lni lni-website-alt"></i>
                            </div>
                            <div class="content">
                                <h3>Custom Software Development</h3>
                                <p>
                                    From sleek corporate websites to dynamic e-commerce platforms, we specialize in crafting custom web
                                    solutions tailored to your unique needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp">
                        <div class="single-feature">
                            <div class="icon">
                                <i class="lni lni-cart-full"></i>
                            </div>
                            <div class="content">
                                <h3>E-commerce Solutions</h3>
                                <p>
                                    Whether you're a startup or an established enterprise, we build robust e-commerce platforms that
                                    streamline operations, enhance customer experience, and drive sales.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp">
                        <div class="single-feature">
                            <div class="icon">
                                <i class="lni lni-pencil-alt"></i>
                            </div>
                            <div class="content">
                                <h3>UI/UX Design</h3>
                                <p>
                                    Our talented designers create immersive user experiences that captivate and convert, ensuring your
                                    digital presence stands out in a crowded online landscape.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp">
                        <div class="single-feature">
                            <div class="icon">
                                <i class="lni lni-target-customer"></i>
                            </div>
                            <div class="content">
                                <h3>Digital Marketing</h3>
                                <p>
                                    From SEO strategies to social media campaigns, we help businesses reach their target audience and
                                    maximize their online visibility through data-driven digital marketing solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default WhatWeDo