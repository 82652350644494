import React from 'react';
import LOGO from "../assets/img/logo/logo.png";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="widget-wrapper">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="footer-widget">
                                <div className="logo mb-30">
                                    <a href="/">
                                        <img className="footer_img" src={LOGO} alt="" />
                                    </a>
                                </div>
                                <p className="desc mb-30 text-white">
                                    Crafting digital experiences. Let's build a software that fulfills your needs!
                                </p>
                                <ul className="socials">
                                    <li>
                                        <a href="https://www.facebook.com/profile.php?id=61559402330120">
                                            <i className="lni lni-facebook-filled"></i>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="/#">
                                            <i className="lni lni-twitter-filled"></i>
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="https://www.instagram.com/ignisweb.dev/" target="_blank" rel="noopener noreferrer">
                                            <i className="lni lni-instagram-filled"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/ignis-web-solutions/about/" target='_blank'>
                                            <i className="lni lni-linkedin-original"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-2 col-lg-2 col-md-6">
                            <div className="footer-widget">
                                <h3>Our company</h3>
                                <ul className="links">
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#whatWeDo">What we do</a></li>
                                    <li><a href="#about">About us</a></li>
                                    <li><a href="#why">What we offer</a></li>
                                    <li><a href="#contact">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-widget">
                                <h3>Legal</h3>
                                <ul className="links">
                                    <li><a href='/#' >Privacy policy</a></li>
                                    <li><a href="/#">Terms of service</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-widget">
                                <h3>Find Us</h3>
                                <ul className="links">
                                    <li><a>Str. Ahmet Krasniqi </a> </li>
                                    <li><a> 10000 Prishtina,<br /> Republic of Kosova</a> </li>
                                    <li><a href="mailto:info@iginisweb.dev">info@ignisweb.dev</a> </li>
                                    <li><a href="tel:+38345944338">+383(0)45 944 338 </a> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
