import logo from './logo.svg';
import './App.css';
import Footer from './components/footer';
import ContactForm from './components/contactForm';
import WhyUs from './components/whyUs';
import Aboutus from './components/aboutus';
import WhatWeDo from './components/whatWeDo';
import Hero from './components/hero';
import Header from './components/header';

function App() {
  return (
    <>
      <Header />
      <Hero />
      <WhatWeDo />
      <Aboutus />
      <WhyUs />
      <ContactForm />
      <Footer />
      <a href="#" class="scroll-top btn-hover">
        <i class="lni lni-chevron-up"></i>
      </a>
    </>

  );
}

export default App;
