import AWN from 'awesome-notifications';
import React, { useState } from 'react';

const ContactForm = () => {
    let notifier = new AWN();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "https://formspree.io/f/xleqzybw"); // Replace "your_email_here" with your Formspree endpoint
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                notifier.success('Thanks for contacting us, we will get back at you ASAP!');
                setFormData({ name: '', email: '', message: '' });
            } else {
                // NotificationManager.error('Error sending message!');
            }
        };
        xhr.send(new FormData(event.target));
    };

    return (
        <section id="contact" className="subscribe-section pt-120">
            <div className="container">
                <div className="subscribe-wrapper img-bg">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-7">
                            <div className="section-title mb-15">
                                <h2 className="text-white mb-25">Lets collaborate!</h2>
                                <p className="text-white pr-5">
                                We're excited to hear from you! Please fill out the form below and let's create something amazing together.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-5">
                            <form onSubmit={handleSubmit} className="subscribe-form">
                                <input className="mb-2" type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your Full Name" />
                                <input className="mb-2" type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email" />
                                <textarea className="mb-2 w-100" name="message" value={formData.message} onChange={handleChange} placeholder="Your Message"></textarea>
                                <button type="submit" className="main-btn btn-hover">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <NotificationContainer /> */}
        </section>
    );
}

export default ContactForm;
