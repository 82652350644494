import React from 'react'

const Aboutus = () => {
    return (
        <section id="about" class="about-section pt-150">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-6 col-lg-6">
                        <div class="about-img">
                            <img src="assets/img/about/progressive_app.svg" alt="" class="w-100" />
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                        <div class="about-content">
                            <div class="section-title mb-30">
                                <h2 class="mb-25 wow fadeInUp" data-wow-delay=".2s">
                                    Cross-Platform Solutions
                                </h2>
                                <p class="wow fadeInUp" data-wow-delay=".4s">
                                    At IGNIS, we excel in crafting tailored software solutions that seamlessly integrate web
                                    and mobile platforms. From web applications to mobile apps, our expert developers leverage cutting-edge
                                    technologies to bring your unique ideas to life. We prioritize collaboration, ensuring that every aspect
                                    of the development process is aligned with your goals and objectives. Let us transform your vision into
                                    a powerful, user-friendly software solution that drives growth and innovation for your business.
                                </p>
                            </div>
                            <a href="javascript:void(0)" class="main-btn btn-hover border-btn wow fadeInUp"
                                data-wow-delay=".6s">Discover More</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default Aboutus