import React from 'react'
import collab from "../assets/img/new/Collab.svg"
import qa from "../assets/img/new/QA.svg"
import experts from "../assets/img/new/experts.svg"
import inovative from "../assets/img/new/inovative.svg"
import support from "../assets/img/new/support.svg"
import unique from "../assets/img/new/unique.svg"

const WhyUs = () => {
    return (
        <section id="why" class="feature-extended-section pt-100">
            <div class="feature-extended-wrapper">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
                            <div class="section-title text-center mb-60">
                                <h2 class="mb-25 wow fadeInUp" data-wow-delay=".2s">
                                    What We Offer
                                </h2>
                                <p class="wow fadeInUp" data-wow-delay=".4s">
                                Discover our tailored solutions, expertise, and innovative approach for your business success.

                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-feature-extended">
                                <div class="icon">
                                    <img className="col-4" src={experts} alt="" />
                                </div>
                                <div class="content">
                                    <h3>Expertise</h3>
                                    <p>
                                        With a team of seasoned professionals, we bring years of experience and expertise to the table, ensuring that your project is in capable hands.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-feature-extended">
                                <div class="icon">
                                <img className="col-4" src={unique} alt="" />
                                </div>
                                <div class="content">
                                    <h3>Custom Solutions</h3>
                                    <p>
                                        We understand that every business is unique. That's why we tailor our solutions to fit your specific needs, delivering bespoke results that align with your objectives.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-feature-extended">
                                <div class="icon">
                                <img className="col-4" src={inovative} alt="" />
                                </div>
                                <div class="content">
                                    <h3>Innovation</h3>
                                    <p>
                                        We're not just developers; we're innovators. Constantly exploring new technologies and methodologies, we strive to push the boundaries of what's possible to deliver cutting-edge solutions that keep you ahead of the curve.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-feature-extended">
                                <div class="icon">
                                <img className="col-4" src={collab} alt="" />
                                </div>
                                <div class="content">
                                    <h3>Collaborative Approach</h3>
                                    <p>
                                        Your input is invaluable to us. We believe in a collaborative approach, working closely with you every step of the way to ensure that the end product exceeds your expectations.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-feature-extended">
                                <div class="icon">
                                <img className="col-4" src={qa} alt="" />
                                </div>
                                <div class="content">
                                    <h3>Quality Assurance</h3>
                                    <p>
                                        Quality is at the forefront of everything we do. From initial concept to final delivery, we maintain rigorous standards of quality assurance to ensure that your project is flawless and ready to impress.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-feature-extended">
                                <div class="icon">

                                <img className="col-4" src={support} alt="" />
                                </div>
                                <div class="content">
                                    <h3>Support & Maintenance</h3>
                                    <p>
                                        Our commitment doesn't end with project delivery. We offer ongoing support and maintenance services to keep your solution running smoothly and to address any issues that may arise.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default WhyUs